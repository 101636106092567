.result__img {
    font-size: 4rem;
}

.result__hash a {
    color: #FF00FF;
}

.result__error {
    line-break: anywhere;
    font-size: 12pt;
}
.gov__capa {
  text-align: center;
}

.gov__capa h4 {
  margin: .6rem;
}

.gov__capa img {
  width: 60px;
  margin-top: 2rem;
}

.gov__capa p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .8rem;
}

.gov__capa span {
  padding: .2rem 1rem;
  border-radius: .8rem;
  background-color: #16161690;
  font-size: small;
}

.gov__balance {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.gov__balance div {
  margin: .6rem 0;
  width: 40%;
}

.gov__balance div h4, p {
  margin: .3rem;
}

.gov__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

.gov__title h2 {
  margin: 0;
}

.gov__empty {
  text-align: center;
}

.gov__empty i {
  margin-top: 2rem;
  font-size: 4rem;
  animation: 2s infinite alternate slide;
}

@keyframes slide {
  from {
    transform: translate(-3rem, 0);
  }

  to {
    transform: translate(3rem, 0);
  }
}

.gov__empty h4 {
  margin-top: 0.4rem;
}

.prop__input {
  width: 85%;
}

.prop__field__title {
  text-align: left;
  margin-top: 1rem;
  margin-inline: 8%;
}

.prop__deposit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: .4rem;
  margin-right: 1rem;
}

.prop__deposit img {
  width: 20px;
}

/* GOV PROP */
.gov__prop p {
  margin-left: 0;
  font-size: 11pt;
}

.gov__prop__id {
  font-weight: 300;
  margin-top: 1rem;
}

.gov__prop__id span {
  font-weight: 600;
}


.gov__prop h4 {
  margin-top: .2rem;
  margin-bottom: .2rem;
}

.gov__prop a {
  font-size: 11pt;
}

.vote__options {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.vote__options button {
  display: block;
  padding: 1rem;
  width: 5rem;
  font-size: large;
}

.vote__option__no, .vote__option__yes {
  background-color: #161616;
  border: 2px solid #161616;
}

.vote__option__no--active {
  color: #FF7B72;
  background-color: #FF7B7220;
  border: 2px solid #FF7B7220;
}

.vote__option__no:hover {
  color: #FF7B72;
  border: 2px solid #FF7B72;
  background-color: #FF7B7220;
}

.vote__option__yes--active {
  color: #7EE787;
  background-color: #7EE78720;
  border: 2px solid #7EE78720;
}

.vote__option__yes:hover {
  color: #7EE787;
  background-color: #7EE78720;
  border: 2px solid #7EE787;
}

.gov__prop__votes__chart {
  background-color: #161616;
  width: 100%;
  height: .6rem;
  border-radius: .3rem;
  margin: .6rem 0;
  margin-top: 1rem;
}

.gov__prop__votes__data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: small;
  font-weight: 500;
  margin-bottom: 1rem;
}

.gov__prop__votes__data b {
  font-weight: 600;
}

.gov__prop__votes__data div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.prop__endtime {
  font-size: small;
  margin-top: 1rem;
  opacity: .8;
}

.prop__endtime span {
  font-size: small;
  font-weight: 600;
}

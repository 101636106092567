.airdrop__button {
  margin: 0 auto;
  margin-bottom: 0.4rem;
}

.airdrop p {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.airdrop__fee {
  font-size: 11pt;
  margin: 1rem 0;
  margin-bottom: 1.6rem;
}

.airdrop__amount {
  font-size: 12pt;
  font-weight: 600;

  margin: 0;
  margin-top: 1.4rem;
}
.button {
  border: 2px solid #ff00ff;
  background-color: #ff00ff;
  cursor: pointer;
  color: white;
  padding: 0.4rem 1.2rem;

  border-radius: 0.6rem;
  font-size: medium;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  transition: 0.3s;
}

.button:hover {
  border: 2px solid transparent;
  background-color: #f501fb30;
  color: #ff00ff;
}

.button__secondary {
  border: 2px solid #ff00ff;
  background-color: transparent;
  cursor: pointer;
  color: white;
  padding: 0.4rem 1.2rem;

  border-radius: 0.6rem;
  font-size: medium;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  transition: 0.3s;
}

.button__secondary:hover {
  border: 2px solid transparent;
  background-color: #f501fb30;
  color: #ff00ff;
}

header {
  width: 16rem;
  height: calc(100vh - 4rem);
  padding: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
}

header img {
  width: 80%;
  margin: 0.4rem 10%;
}

.link__container {
  display: flex;
  flex-direction: column;
  margin-top: 1.4rem;
  gap: .4rem;
}

.link {
  text-decoration: none;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  font-size: large;
  font-weight: 600;
  padding: 0.6rem 1.2rem;
  border-radius: 0.6rem;
  margin: 0.2rem 0;
  transition: 0.3s;
}

.link:hover {
  background-color: #F501FB30;
}

.link i {
  font-size: x-large;
  margin-right: 1rem;
}

.link__active {
  color: #F501FB;
  background-color: #2A2A2A;
}

.email {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #FFFFFF;
  text-decoration: none;
  transition: 0.3s;
}

.email i {
  font-size: x-large;
  margin-right: 0.6rem;
}

.email:hover {
  color: #F501FB;
}

.logo__small {
  width: 50px;
  display: none;
}

@media only screen and (max-width: 1060px) {
  header {
    width: fit-content;
  }

  .logo__small {
    display: inline;
  }

  .logo__large {
    display: none;
  }

  .link,
  .email {
    font-size: 0;
    width: fit-content;
  }

  .link i {
    font-size: x-large;
    margin-right: 0;
  }
}

@media only screen and (max-width: 659px) {
  header {
    width: 100vw;
    height: fit-content;
    position: fixed;
    top: 100%;
    left: 0;
    flex-direction: row;
    justify-content: center;
    padding: 1rem 0;
    background-color: #161616;
    transform: translate(0, -100%);
  }

  .link__container {
    display: flex;
    flex-direction: row;
    margin-top: 1.4rem;
  }

  .link__container {
    margin-top: 0;
  }

  .logo__small {
    display: none;
  }

  .email {
    display: none;
  }
}


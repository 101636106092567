.home table {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 0.4rem;
}

.borrow td {
  display: table-cell;
  vertical-align: inherit;
  margin-right: 100%;
}

.home__table__flex {
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: 600;
}

.home__asset__icon {
  width: 30px;
  border-radius: 50%;
  margin-right: 0.4rem;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: #161616;
}

.app {
  display: flex;
  justify-content: center;
}

main {
  width: min(clamp(30vw,400px,calc(90% - 1rem)),560px);
  margin-top: 2rem;
}

.logo__mobile {
  display: none;
}

section {
  background-color: #2A2A2A;
  border-radius: 0.6rem;
  width: calc(100% - 3.2rem);
  margin-top: 1rem;
  padding: 0.8rem 1.6rem;
}

.section__title {
  margin-top: 5rem;
}

.section__bottom {
  height: 6rem;
}

section h3 {
  margin: 0.4rem 0;
}

button {
  border: 2px solid #ff00ff;
  background-color: #ff00ff;
  cursor: pointer;
  color: white;
  padding: 0.3rem 1.4rem;

  border-radius: 0.4rem;
  font-size: small;
  font-weight: 600;

  transition: 0.3s;
}

button:hover {
  border: 2px solid transparent;
  background-color: #f501fb30;
  color: #ff00ff;
}

@media only screen and (max-width: 659px) {
  .logo__mobile {
    display: inline;
    width: 33vw;
    padding-bottom: 1rem;
  }

  .section__title {
    margin-top: 2rem;
  }
}

a {
  color: #ff00ff;
}

/* POPUPS */
.modal__background {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #16161680;
  left: 0;
  top: 0;
}

.modal {
  background-color: #2A2A2A;
  position: relative;
  width: clamp(25vw, 400px, 90vw);
  text-align: center;
  border-radius: .6rem;
  padding: 1.4rem .8rem;
}

.modal__close {
  background-color: transparent;
  border: 0;
  padding: .2rem .6rem;
  color: white;
  cursor: pointer;
  font-size: 20pt;
  position: absolute;
  right: .4rem;
  top: .4rem;
}

.modal__close:hover {
  border: 0;
  background-color: transparent;
  color: white;
}

.modal__input {
  background-color: #161616;
  color: white;
  width: 70%;
  border-radius: .4rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal__input input {
  outline: none;
  border: 0;
  background-color: #161616;
  color: white;
  font-size: 12pt;
  border-radius: .4rem;
  padding: .4rem 1rem;
  width: 100%;
}

.modal__input textarea {
  outline: none;
  border: 0;
  background-color: #161616;
  color: white;
  font-size: 12pt;
  border-radius: .4rem;
  padding: 1rem 1rem;
  width: 100%;
  resize: vertical;
  min-height: 6rem;
}

.modal__input button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  text-decoration: underline;
  color: #FF00FF;
  margin-right: .4rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #F50BFB;
  background: linear-gradient(0deg, #feb1a0 0%, #fd6ee1 20%, #a325f5 100%);
}
.borrow table {
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 0.4rem;
}

.borrow td {
  display: table-cell;
  vertical-align: inherit;
  margin-right: 100%;
}

.borrow__table__flex {
  display: flex;
  align-items: center;
  justify-content: left;
}

.borrow__asset__icon {
  width: 30px;
  border-radius: 50%;
  margin-right: 0.4rem;
}

.borrow__button {
  font-size: small;
  font-weight: 500;
  padding: 0.25rem 1.2rem;
}

.modal__button {
  margin-top: 2rem;
  margin-bottom: 0.4rem;
}

.borrow__container p {
  font-size: 28pt;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 700;
}

.borrow__container h4 {
  margin-bottom: 0;
  font-weight: 600;
}

.borrow__modal__value p {
  font-size: 24pt;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.borrow__modal__value input {
  -webkit-appearance: none;
  appearance: none;
  width: 60%;
  height: 10px;
  background: #161616;
  border-radius: 5px;
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

.borrow__modal__value input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fc0cff;
  cursor: pointer;
}

.borrow__modal__value input::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fc0cff;
  cursor: pointer;
}

.borrow__limit__icon {
  width: 44px;
  margin-left: .5rem;
}

.borrow__limt {
  display: flex;
  align-items: center;
  justify-content: left;
}

.borrow__amount {
  display: flex;
  align-items: center;
  justify-content: center;
}

.borrow__amount__icon {
  width: 30px;
  margin-left: .5rem;
}
.popup__bg {
  background-color: #2A2A2A80;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
}

.popup {
  background-color: #161616;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: min(90%, 400px);
  text-align: center;
  padding: 1rem 2.4rem;
  border-radius: 0.4rem;
}

.popup__close {
  position: absolute;
  top: .6rem;
  right: .8rem;
  cursor: pointer;
  color: white;
  background-color: transparent;
  border: 0;
  font-size: 20pt;
}

.popup__button {
  display: flex;
  gap: .6rem;
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  margin: 1rem 0;
  padding: .6rem 1rem;
  border-radius: .4rem;
}

.popup__button img {
  width: 20px;
}